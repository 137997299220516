<template>
  <div class="layout relative mx-auto">
    <slot></slot>
  </div>
</template>

<style lang="stylus" scoped>

CONTENT_MIN_WIDTH = 350px
CONTENT_MAX_WIDTH = 780px

.layout
  width 100dvw
  min-width CONTENT_MIN_WIDTH
  max-width CONTENT_MAX_WIDTH
  height 100dvh
  min-height -webkit-fill-available
</style>
